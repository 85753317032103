<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <v-card-text class="px-0 py-0">
          <ClassGroupCalendary
            v-if="selectedClassGroup"
            :zClassGroup="selectedClassGroup"
            :onlyAllowedLesson="null"
            :readable="true"
            :forStudent="true"
          ></ClassGroupCalendary>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
import ClassGroupCalendary from "@/sms/huvaari/ClassGroupCalendary.vue";
export default {
  name: "light-table-transparent",
  computed: {
    ...sync("*"),
  },
  components: {
    ClassGroupCalendary,
  },
  data() {
    return {
      selectedClassGroup: null,
    };
  },

  created() {
    console.log(this.userData);
    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .doc("department-" + this.userData.ACADEMIC_LEVEL)
      .collection("programs")
      .doc(this.userData.STUDENT_GROUP_ID.toString())
      .get()
      .then((doc) => {
        this.selectedClassGroup = doc.data();
        this.selectedClassGroup.id = doc.id;
        this.selectedClassGroup.ref = doc.ref;
        console.log(this.selectedClassGroup);
      });
  },

  methods: {},
};
</script>
